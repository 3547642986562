import { currentVersion } from '../app/appVersion';
export const environment = {
  googleAnalyticsMeasurementId: 'G-4G1VE1QGMY',
  production: false,
  baseUrl: 'https://radial-st1.bpost.cloud/api/radial',
  rucUrl: 'https://radial-st1.bpost.cloud/api/ruc',
  pingUrl: 'https://radial-st1.bpost.cloud/api/ping/userinfo',
  qlikViewGraphUrl:
    'https://radial-st1.bpost.cloud/api/radial/clientbaselinereports',
  generateReportUrl:
    'https://radial-st1.bpost.cloud/generatereport/cpyn5fak3mqn7avitwbrcpxsqe0diyob.lambda-url.eu-west-1.on.aws',
  cbrGraphUrl:
    'https://radial-st1.bpost.cloud/api/radial/clientbaselinereports',
  clientConfigUrl: 'https://radial-st1.bpost.cloud/api/radial/clientconfig/',
  userConfigUrl: 'https://radial-st1.bpost.cloud/api/radial/userconfig/',
  reportUrl: 'https://datawarehouse-report.s3.eu-west-1.amazonaws.com/',
  logout: 'https://login.bpost.be/idp/startSLO.ping',
  rootUrl: 'https://radial-st1.bpost.cloud',
  VERSION: currentVersion,
  DEPLOYED_BRANCH_NAME: 'develop(f60893f9f99d3b2837fa668ef1e3e567172164c9)',
  DEPLOYED_BRANCH_URL: 'https://bitbucket.org/bpost_deliveryparcelsint/rcp-frontend-v2/commits/f60893f9f99d3b2837fa668ef1e3e567172164c9',
};
