import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  /**
   * Imp point about snackbar:
   * The snack bar will not hide even after its duration(life parameter) ends.
   * This happens when the mouse cursor is over the snackbar.This is not a bug
   * it is an inteded feature.
   * https://github.com/twbs/bootstrap/issues/32820 (although it is for bootstrap but it seems primeng team is also following this)
   */
  constructor(public messageService: MessageService) {}
  showSuccess(summary: string, detail: string, life?) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'success',
      summary,
      detail,
      icon: 'pi pi-custom-success-icon',
      life,
    });
  }
  showError(summary: string, detail: string, life?) {
    this.messageService.clear();
    this.messageService.add({
      severity: 'error',
      summary,
      detail,
      icon: 'pi pi-custom-error-icon',
      life,
    });
  }
  clearSnackeBar() {
    this.messageService.clear();
  }
}
